import axios from 'axios';
import qs from "qs";

/**
 * 测试库地址 https://3030.hsx777.cn/
 * 正式库地址 https://m.jiihee.com/
 */
/*axios.defaults.baseURL = process.env.NODE_ENV == 'development' ? 'http://106.15.48.65:10080/' : 'http://backend-api-01.newbee.ltd/api/v1'*//*axios.defaults.baseURL = process.env.NODE_ENV == 'development' ? 'http://106.15.48.65:10080/' : 'http://backend-api-01.newbee.ltd/api/v1'*/
axios.defaults.baseURL = process.env.NODE_ENV == 'development' ? 'http://localhost:3030/':'https://m.jiihee.com/'
// axios.defaults.baseURL = process.env.NODE_ENV == 'http://192.168.31.43:9090'
// request拦截器
axios.interceptors.request.use(config => {
    /*if (store.getters.token) {
      config.headers['tokenId'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
      config.headers['tokenId'] =localStorage.getItem('token')
    }*/
    config.headers['tokenId'] = localStorage.getItem('token')
    config.headers['appCode'] = "CAR_ADMIN_PUB";
    config.headers['version'] = "0.0.1";
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    if(config.method === 'post'&&config.dataType !== 'json') {
        config.data = qs.stringify({...config.data})
    }

    if(config.dataType === 'json'){
        config.headers['Content-Type'] = 'application/json;charset=UTF-8';
        config.data =config.params;
    }
    return config
}, error => {
    Promise.reject(error)
})


axios.interceptors.response.use(response => {
    return response.data
})


export default axios

