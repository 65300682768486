<template>
  <div>
    <PullDown class="pull-down"></PullDown>
    <div class="succeed">
      <img :src="img">
      <p>提交成功</p>
      <p>
        厚思想将在2个工作日内联系您，以便核实您的申请，请注意接听来电！
      </p>
      <button @click="rollback">回到首页</button>
    </div>
  </div>

</template>
<script>
import PullDown from '@/components/pull-down.vue'
export default {
  components:{
    PullDown
  },
  data(){
    return{
      img:require('@/assets/succeed.png'),
    }
  },
  methods:{
    rollback(){
      this.$router.push({path:'/'})
    }
  }
}
</script>
<style lang="less">
.succeed{
  text-align: center;
  margin-top: 150px;
  img{
    margin-top: 150px;
    width: 431px;
    height: 364px;
  }
  p:nth-of-type(1){
    height: 60px;
    font-size: 44px;
    font-weight: 600;
    color: #09172F;
    line-height: 60px;
    margin-top: 26px;
  }
  p:nth-of-type(2){
    width: 526px;
    margin: 0 auto;
    margin-top: 44px;
    height: 112px;
    font-size: 28px;
    font-weight: 400;
    color: #516C95;
    line-height: 40px;
  }
  button{
    border: none;
    color: #FFFFFF;
    width: 262px;
    height: 80px;
    background: #527E69;
    border-radius: 56px;
  }
}
</style>