<template>
  <div  key="1">
    <div>
      <img src="https://img.hsx777.cn/mjiihee/logotitle.png" />
      <a v-if="!isWeixin" :href="CODE" target="_blank"><button> 立即下载</button></a>
      <a v-if="isWeixin" href="https://a.app.qq.com/o/simple.jsp?pkgname=com.hsx.jiiheeapp" target="_blank"><button> 立即下载</button></a>
<!--      <button @click="log">登录网页版</button>-->
    </div>
  </div>
</template>
<script>

export default {
  data(){
    return{
      show:false,
    }
  },
  props:{
    "CODE":String,
    "isWeixin":Boolean,
  },
  methods:{
    target(){
      this.show = true;
      this.$emit('setShow',this.show)
    },
    log(){
      console.log(window.location.origin)
        if (window.location.origin == 'https://3030.hsx777.cn/'){
          window.location.href = 'https://3031.hsx777.cn/';
        }else if(window.location.origin == 'https://m.jiihee.com'){
          window.location.href = 'https://mall.jiihee.com';
        }
    }
  }
}
</script>
<style lang="less">
.pull-down{
  z-index:9999999 !important;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  vertical-align: middle;
  padding-left: 34px;
  background: #fff;
  height: 146px;
  box-sizing: border-box;
&>div{
  width: 95%;
  position: absolute;
  bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
img{
  height: 48px;
  vertical-align: middle;
  margin-right: 56px;
}
button{
  width: 156px;
  height: 68px;
  border-radius: 40px;
  border: 2px solid #527E69;
  font-size: 24px;
  font-weight: 400;
  color: #527E69;
  vertical-align: middle;
  background: #fff;
}
a{
  text-decoration: none;
  color: #527E69;
}
button:nth-of-type(1){
  margin-right: 14px;
}
}
}
</style>