import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import succeed from '../views/succeed'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path:'/succeed',
    name:'succeed',
    component:succeed
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
